import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./MainLayout/Footer";
import styled from "styled-components";
import MainNav from "./MainLayout/MainNav";
import { FaArrowUp } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";

const Layout = () => {
  const [hasScrolledPast, setHasScrolledPast] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const tenVH = window.innerHeight * 0.3;

      if (scrollPosition >= tenVH) {
        setHasScrolledPast(true);
      } else {
        setHasScrolledPast(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }
  return (
    <TheMainLayout>
      {hasScrolledPast && (
        <div className="arrow-to-top" onClick={scrollToTop}>
          <FaArrowUp />
        </div>
      )}
      <a
        href="https://wa.me/message/4K7Q26IBVP36P1"
        target="_blank"
        rel="noreferrer"
        className="whatsapp-to-top"
      >
        <RiWhatsappFill />
      </a>
      <div className="header">
        <MainNav />
      </div>
      <div className="outLet">
        <Outlet />
      </div>
      <Footer />
    </TheMainLayout>
  );
};

const TheMainLayout = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    z-index: 10;
    padding: 1rem 10rem;
  }
  .outLet {
    min-height: 70vh;
    height: fit-content;
  }
  @media screen and (max-width: 1200px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 900px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 420px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 350px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
`;
export default Layout;
