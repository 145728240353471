// import React, { useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import styled from "styled-components";
import About from "../About";
import OurRooms from "./OurRooms";
import Testimonials from "./Testimonials";
import Contact from "./Contact";


const Landing = () => {


  return (
    <LandingPage id="head">
      <HeroSection />
      <About />
      <OurRooms />
      <Testimonials />
      <Contact />
    </LandingPage>
  );
};

const LandingPage = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  .arrow-to-top {
    position: fixed;
    bottom: 15vh;
    right: 2rem;
    background: #df9739;
    z-index: 200;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .whatsapp-to-top {
    position: fixed;
    bottom: 7vh;
    right: 2rem;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    color: green;
    z-index: 200;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid #df9739;
  }
  @media screen and (max-width: 1300px) {
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    .arrow-to-top {
      position: fixed;
      bottom: 13vh;
      right: 0.8rem;
      background: #df9739;
      z-index: 200;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    .whatsapp-to-top {
      position: fixed;
      bottom: 7vh;
      right: 0.8rem;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      color: green;
      z-index: 200;
      border-radius: 0.5rem;
      cursor: pointer;
      border: 1px solid #df9739;
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default Landing;
