import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Landing from "./pages/Home/Landing";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Rooms from "./pages/Rooms/Rooms";
import Lagos from "./pages/Lagos/Lagos";
import "react-lazy-load-image-component/src/effects/blur.css";
import Agbor from "./pages/Agbor/Agbor";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="/about" element={<About />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/lagos" element={<Lagos />} />
            <Route path="/agbor" element={<Agbor />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
