import React, { useEffect } from "react";
import styled from "styled-components";
import { FaPhone } from "react-icons/fa";
import LagosVideo from "../../assets/rooms/Agbo/6.jpg";
import AOS from "aos";

const AgborHero = () => {
    useEffect(() => {
      AOS.init({
        offset: 10,
        duration: 800,
        easing: "ease-in-sine",
        delay: 100,
      });
    }, []);
    return (
      <AgborHeroSection>
        <div className="room-hero-content">
          <div className="room-hero-text">
            <h3 data-aos="zoom-in-right">
              Creme Hotels & Suites - Delta State (Agbor)
            </h3>
            <p>
              <FaPhone /> <span>+2347063985240</span>
            </p> <br />
            <p>
             Whatsapp : <span>+2347063985240</span>
            </p>
          </div>
          <div className="room-hero-image">
            <div className="overlay"></div>
            <img src={LagosVideo} alt="Something shadow" />
          </div>
        </div>
      </AgborHeroSection>
    );
};

const AgborHeroSection = styled.div`
  width: 100%;
  height: 80vh;
  padding: 1rem 1rem;
  background-color: var(--bg-body);
  box-sizing: border-box;
  overflow: hidden;
  .room-hero-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    .room-hero-text {
      position: absolute;
      bottom: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 50%);
      z-index: 5;
      h3 {
        color: var(--bg-one);
        text-align: center;
        font-size: 5rem;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: "Allison", sans-serif;
      }
      p {
        font-family: "Jost", sans-serif;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:1rem;
      }
    }
    .room-hero-image {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
    height: 60vh;
    padding: 1rem 1rem;
    background-color: var(--bg-body);
    box-sizing: border-box;
    overflow: hidden;
    .room-hero-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      overflow: hidden;
      position: relative;
      .room-hero-text {
        position: absolute;
        bottom: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, 50%);
        z-index: 5;
        h3 {
          color: var(--bg-one);
          text-align: center;
          font-size: 5rem;
          font-weight: 500;
          letter-spacing: 1px;
          font-family: "Allison", sans-serif;
        }
      }
      .room-hero-image {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.7);
          z-index: 1;
        }
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 60vh;
    padding: 0;
    background-color: var(--bg-body);
    box-sizing: border-box;
    overflow: hidden;
    .room-hero-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0rem;
      overflow: hidden;
      position: relative;
      .room-hero-text {
        width: 100%;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        z-index: 5;
        h3 {
          color: var(--bg-one);
          text-align: center;
          font-size: 3rem;
          font-weight: 500;
          letter-spacing: 1px;
          font-family: "Allison", sans-serif;
        }
      }
      .room-hero-image {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.7);
          z-index: 1;
        }
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default AgborHero;
