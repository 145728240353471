import React from 'react'
import RoomHero from './RoomHero'
import styled from 'styled-components'
import RoomCard from './RoomCard'

const Rooms = () => {
  return (
    <RoomsPage>
      <RoomHero/>
      <RoomCard />
    </RoomsPage>
  )
}
const RoomsPage = styled.div``

export default Rooms