import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoBedOutline } from "react-icons/io5";
import { TiGroupOutline } from "react-icons/ti";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { ourRooms } from "../../assets/projectData";
import AOS from "aos";

const OurRooms = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      offset: 10,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  const [currentSlide, setCurrentSlide] = useState(0);
  const roomsLength = ourRooms.length;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNext = () => {
    setCurrentSlide(currentSlide === roomsLength - 3 ? 0 : currentSlide + 1);
  };

  const handlePrev = () => {
    setCurrentSlide(currentSlide === 0 ? roomsLength - 3 : currentSlide - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentSlide, handleNext]);

  return (
    <RoomSection>
      <div className="left-creme-bg"></div>
      <div className="room-container">
        <h3 className="room-heading" data-aos="zoom-in">
          Our Rooms
        </h3>
        <p className="room-write-up" data-aos="zoom-in-down">
          At our prestigious hotel, each room is a blend of luxury and comfort,
          designed to offer a serene escape with breathtaking views and refined
          decor. Our spacious suites boast plush bedding, contemporary
          furnishings, and carefully curated amenities, ensuring a stay of
          unmatched relaxation. Great toiletries, and an array of in-room
          entertainment options cater to your every need. Each room offers a
          unique ambiance, from sunlit spaces perfect for unwinding to cozy
          corners ideal for quiet reflection. Whether you seek a romantic
          getaway or a business retreat, our rooms provide the perfect balance
          of elegance and tranquility.
        </p>
        <div className="cards">
          <div className="direction" onClick={handlePrev}>
            <FaChevronLeft />
          </div>
          <div className="big-screen-rooms">
            {ourRooms
              .slice(currentSlide, currentSlide + 3)
              .map((room, index) => (
                <div className="card" key={index} data-aos="zoom-in">
                  {/* <div className="price-tag">
                    {room.withAC === 0 ? null : (
                      <p className="amount">
                        &#x20A6; {room.withAC} <span>with AC</span>
                      </p>
                    )}
                    <p className="amount">
                      &#x20A6; {room.fan} <span>without AC</span>
                    </p>
                  </div> */}
                  <div className="image">
                    <img src={room.image} alt="room-imgs" />
                  </div>
                  <div className="card-content">
                    <p className="card-title">{room.name}</p>
                    <div className="info">
                      <p>
                        <IoBedOutline /> <span>{room.bed} King Beds</span>
                      </p>
                      <p>
                        <TiGroupOutline /> <span>{room.guests} Guests</span>
                      </p>
                    </div>
                    <button onClick={() => navigate("/contact")}>
                      BOOK NOW{" "}
                      <span>
                        <FaChevronRight size={10} />
                      </span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="small-screen-rooms">
            {ourRooms
              .slice(currentSlide, currentSlide + 1)
              .map((room, index) => (
                <div className="card" key={index}>
                  {/* <div className="price-tag">
                    {room.withAC === 0 ? null : (
                      <p className="amount">
                        &#x20A6; {room.withAC} <span>with AC</span>
                      </p>
                    )}
                    <p className="amount">
                      &#x20A6; {room.fan} <span>without AC</span>
                    </p>
                  </div> */}
                  <div className="image">
                    <img src={room.image} alt="room-imgs" />
                  </div>
                  <div className="card-content">
                    <p className="card-title">{room.name}</p>
                    <div className="info">
                      <p>
                        <IoBedOutline /> <span>{room.bed} King Beds</span>
                      </p>
                      <p>
                        <TiGroupOutline /> <span>{room.guests} Guests</span>
                      </p>
                    </div>
                    <button>
                      BOOK NOW{" "}
                      <span>
                        <FaChevronRight size={10} />
                      </span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="direction" onClick={handleNext}>
            <FaChevronRight />
          </div>
        </div>
        <Link to="/rooms" className="view-all">
          VIEW ALL ROOMS &nbsp;{" "}
          <span>
            <FaChevronRight size={10} />
          </span>
        </Link>
      </div>
    </RoomSection>
  );
};

const RoomSection = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .room-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 10rem;
    position: relative;
    z-index: 5;
  }
  .left-creme-bg {
    width: 50%;
    height: 100%;
    background: var(--bg-body);
    position: absolute;
    left: 0;
    opacity: 0.4;
    z-index: inherit;
  }
  .room-heading {
    font-size: 4rem;
    font-family: "Allison", system-ui;
    color: var(--bg-two);
    font-weight: 500;
  }
  .room-write-up {
    width: 50%;
    font-family: "Jost", sans-serif;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    text-align: center;
  }
  .small-screen-rooms {
    display: none;
  }
  .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .big-screen-rooms {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 2rem 0;
    }
    .direction {
      color: #535252;
      font-size: 3rem;
      cursor: pointer;
    }
    .card {
      font-family: "Jost", sans-serif;
      width: 32%;
      height: 50%;
      overflow: hidden;
      box-shadow: 0 0 10px #0000001a;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .price-tag {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        background: black;
        padding: 0.8rem 1.2rem;
        color: white;
        font-family: "Jost", sans-serif;
        border-radius: 0.5rem;
        position: absolute;
        top: 40%;
        left: 0;
        font-weight: 500;
      }
      .image {
        height: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        padding: 2rem 1.5rem;
        background: white;
        gap: 2rem;
        .card-title {
          font-size: 1.8rem;
          font-weight: 500;
          margin: 0;
        }
        .info {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1.5rem;
          font-size: 1rem;
          p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
          }
        }
        button {
          background: none;
          font-size: 1rem;
          font-weight: 500;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-family: "Jost", sans-serif;
          gap: 0.6rem;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .view-all {
    text-decoration: none;
    color: var(--bg-two);
    font-family: "Jost", sans-serif;
    font-weight: 500;
    border-bottom: 2px solid var(--bg-two);
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .room-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      position: relative;
      z-index: 5;
    }
    .left-creme-bg {
      width: 50%;
      height: 100%;
      background: var(--bg-body);
      position: absolute;
      left: 0;
      opacity: 0.4;
      z-index: inherit;
    }
    .room-heading {
      font-size: 3rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
    }
    .room-write-up {
      width: 100%;
      font-family: "Jost", sans-serif;
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }

    .cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .big-screen-rooms {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }
      .small-screen-rooms {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }
      .direction {
        color: #535252;
        font-size: 2rem;
        cursor: pointer;
      }
      .card {
        font-family: "Jost", sans-serif;
        width: 100%;
        height: 50%;
        overflow: hidden;
        box-shadow: 0 0 10px #0000001a;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        .price-tag {
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          background: black;
          padding: 0.8rem 1.2rem;
          color: white;
          font-family: "Jost", sans-serif;
          border-radius: 0.5rem;
          position: absolute;
          top: 40%;
          left: 0;
          font-weight: 500;
          font-size: 0.8rem;
        }
        .image {
          height: 50%;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .card-content {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          width: 100%;
          padding: 2rem 1.5rem;
          background: white;
          gap: 2rem;
          .card-title {
            font-size: 1.8rem;
            font-weight: 500;
            margin: 0;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1.5rem;
            font-size: 0.8rem;
            p {
              margin-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
            }
          }
          button {
            background: none;
            font-size: 1rem;
            font-weight: 500;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-family: "Jost", sans-serif;
            gap: 0.6rem;
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .view-all {
      text-decoration: none;
      color: var(--bg-two);
      font-family: "Jost", sans-serif;
      font-weight: 500;
      border-bottom: 2px solid var(--bg-two);
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .room-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      position: relative;
      z-index: 5;
    }
    .left-creme-bg {
      width: 50%;
      height: 100%;
      background: var(--bg-body);
      position: absolute;
      left: 0;
      opacity: 0.4;
      z-index: inherit;
    }
    .room-heading {
      font-size: 3rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
    }
    .room-write-up {
      width: 100%;
      font-family: "Jost", sans-serif;
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }

    .cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .big-screen-rooms {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }
      .small-screen-rooms {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }
      .direction {
        color: #535252;
        font-size: 2rem;
        cursor: pointer;
      }
      .card {
        font-family: "Jost", sans-serif;
        width: 100%;
        height: 50%;
        overflow: hidden;
        box-shadow: 0 0 10px #0000001a;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        .price-tag {
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          background: black;
          padding: 0.8rem 1.2rem;
          color: white;
          font-family: "Jost", sans-serif;
          border-radius: 0.5rem;
          position: absolute;
          top: 40%;
          left: 0;
          font-weight: 500;
          font-size: 0.8rem;
        }
        .image {
          height: 50%;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .card-content {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          width: 100%;
          padding: 2rem 1.5rem;
          background: white;
          gap: 2rem;
          .card-title {
            font-size: 1.8rem;
            font-weight: 500;
            margin: 0;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1.5rem;
            font-size: 0.8rem;
            p {
              margin-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
            }
          }
          button {
            background: none;
            font-size: 1rem;
            font-weight: 500;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-family: "Jost", sans-serif;
            gap: 0.6rem;
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .view-all {
      text-decoration: none;
      color: var(--bg-two);
      font-family: "Jost", sans-serif;
      font-weight: 500;
      border-bottom: 2px solid var(--bg-two);
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .room-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      position: relative;
      z-index: 5;
    }
    .left-creme-bg {
      width: 50%;
      height: 100%;
      background: var(--bg-body);
      position: absolute;
      left: 0;
      opacity: 0.4;
      z-index: inherit;
    }
    .room-heading {
      font-size: 3rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
    }
    .room-write-up {
      width: 100%;
      font-family: "Jost", sans-serif;
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }

    .cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .big-screen-rooms {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }
      .small-screen-rooms {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }
      .direction {
        color: #535252;
        font-size: 2rem;
        cursor: pointer;
      }
      .card {
        font-family: "Jost", sans-serif;
        width: 100%;
        height: 50%;
        overflow: hidden;
        box-shadow: 0 0 10px #0000001a;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        .price-tag {
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          background: black;
          padding: 0.8rem 1.2rem;
          color: white;
          font-family: "Jost", sans-serif;
          border-radius: 0.5rem;
          position: absolute;
          top: 40%;
          left: 0;
          font-weight: 500;
          font-size: 0.8rem;
        }
        .image {
          height: 50%;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .card-content {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          width: 100%;
          padding: 2rem 1.5rem;
          background: white;
          gap: 2rem;
          .card-title {
            font-size: 1.8rem;
            font-weight: 500;
            margin: 0;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1.5rem;
            font-size: 0.8rem;
            p {
              margin-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
            }
          }
          button {
            background: none;
            font-size: 1rem;
            font-weight: 500;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-family: "Jost", sans-serif;
            gap: 0.6rem;
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .view-all {
      text-decoration: none;
      color: var(--bg-two);
      font-family: "Jost", sans-serif;
      font-weight: 500;
      border-bottom: 2px solid var(--bg-two);
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .room-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      position: relative;
      z-index: 5;
    }
    .left-creme-bg {
      width: 50%;
      height: 100%;
      background: var(--bg-body);
      position: absolute;
      left: 0;
      opacity: 0.4;
      z-index: inherit;
    }
    .room-heading {
      font-size: 3rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
    }
    .room-write-up {
      width: 100%;
      font-family: "Jost", sans-serif;
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }

    .cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .big-screen-rooms {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0;
      }
      .small-screen-rooms {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 1rem 0;
      }
      .direction {
        color: #535252;
        font-size: 1.2rem;
        cursor: pointer;
      }
      .card {
        font-family: "Jost", sans-serif;
        width: 100%;
        height: 50%;
        overflow: hidden;
        box-shadow: 0 0 10px #0000001a;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        .price-tag {
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          gap: 0.2rem;
          background: black;
          padding: 0.5rem 1rem;
          color: white;
          font-family: "Jost", sans-serif;
          border-radius: 0.5rem;
          position: absolute;
          top: 40%;
          left: 0;
          font-weight: 500;
          font-size: 0.8rem;
        }
        .image {
          height: 50%;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .card-content {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          width: 100%;
          padding: 1rem 1.5rem;
          background: white;
          gap: 1rem;
          .card-title {
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1rem;
            font-size: 0.8rem;
            p {
              margin-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
            }
          }
          button {
            background: none;
            font-size: 0.8rem;
            font-weight: 500;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-family: "Jost", sans-serif;
            gap: 0.6rem;
            span {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
    .view-all {
      text-decoration: none;
      color: var(--bg-two);
      font-family: "Jost", sans-serif;
      font-weight: 500;
      border-bottom: 2px solid var(--bg-two);
      display: flex;
      align-items: center;
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 350px) {
  }
`;

export default OurRooms;
