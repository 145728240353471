import React, { useState } from "react";
import styled from "styled-components";

const Contact = () => {
  const [lagos, setLagos] = useState(true);
  const toggleLagos = () => setLagos(!lagos);
  return (
    <ContactPage>
      <h4 className="contact-heading">Contact Us</h4>
      <div className="contact-container">
        <div className="contact-form">
          <form>
            <h5 className="form-heading">Send Us A Message</h5>
            <div className="contact-input">
              <label htmlFor="name">Name : </label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="contact-input">
              <label htmlFor="name">Email : </label>
              <input type="email" name="name" id="name" />
            </div>
            <div className="contact-input">
              <label htmlFor="name">Phone Number : </label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="contact-input">
              <label htmlFor="name">Message : </label>
              <textarea name="" rows="10" id=""></textarea>
            </div>
            <button type="submit">SEND MESSAGE</button>
          </form>
        </div>
        <div className="maps">
          <div className="map-heading">
            <h5
              className={lagos ? "active-h5" : "normal-h5"}
              onClick={toggleLagos}
            >
              Lagos Branch
            </h5>
            <h5
              className={!lagos ? "active-h5" : "normal-h5"}
              onClick={toggleLagos}
            >
              Delta Branch
            </h5>
          </div>
          <div className="the-map">
            {lagos ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d495.4583556013619!2d3.365731!3d6.563669!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d778a78bddd%3A0x9318405b55be7baf!2sCreme%20Suites%20And%20Hotel!5e0!3m2!1sen!2sng!4v1727556477314!5m2!1sen!2sng"
                width="100%"
                height="550"
                style={{
                  border: 0,
                  borderRadius: ".5rem",
                  boxShadow: "0 0 10px #0000001a",
                }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            ) : (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.0873295273122!2d6.213661985126019!3d6.252223610122183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10414e37a4f60a53%3A0x692ff7e54e27fff1!2s20%20Deeper%20Life%20Rd%2C%20Boji%20Boji%2C%20Agbor%20321103%2C%20Delta!5e0!3m2!1sen!2sng!4v1727558895577!5m2!1sen!2sng"
                width="100%"
                height="550"
                style={{
                  border: 0,
                  borderRadius: ".5rem",
                  boxShadow: "0 0 10px #0000001a",
                }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </ContactPage>
  );
};

const ContactPage = styled.div`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 2rem 10rem;
  background: var(--bg-body);
  .contact-heading {
    font-size: 4rem;
    font-family: "Allison", system-ui;
    color: var(--bg-two);
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .contact-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
    .contact-form {
      width: 48%;
      form {
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        .form-heading {
          font-size: 1.5rem;
          font-weight: 500;
          color: var(--bg-two);
          font-family: "Jost", sans-serif;
        }
        .contact-input {
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: start;
          flex-direction: column;
          gap: 0.2rem;
          input {
            width: 100%;
            padding: 0.8rem 1rem;
            outline: none;
            font-size: 0.8rem;
            font-weight: 400;
            border: none;
            font-family: "Jost", sans-serif;
          }
          label {
            font-size: 1rem;
            font-weight: 400;
            color: var(--bg-two);
            font-family: "Jost", sans-serif;
          }
          textarea {
            resize: none;
            width: 100%;
            padding: 0.8rem 1rem;
            font-family: "Jost", sans-serif;
            outline: none;
            font-size: 0.8rem;
            font-weight: 400;
            border: none;
          }
        }
        button {
          padding: 0.8rem 1rem;
          border: none;
          outline: none;
          background-color: var(--primary);
          color: var(--bg-one);
          font-size: 0.8rem;
          font-weight: 400;
          font-family: "Jost", sans-serif;
          cursor: pointer;
          width: 100%;
        }
      }
    }
    .maps {
      width: 48%;
      height: fit-content;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2rem;
      .map-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        .active-h5 {
          font-size: 1.5rem;
          font-weight: 500;
          color: var(--bg-two);
          font-family: "Jost", sans-serif;
          border-bottom: 2px solid var(--bg-two);
          cursor: pointer;
        }
        .normal-h5 {
          font-size: 1.5rem;
          font-weight: 500;
          color: lightgray;
          font-family: "Jost", sans-serif;
          cursor: pointer;
        }
      }
      .the-map {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 2rem 1rem;
    background: var(--bg-body);
    .contact-heading {
      font-size: 4rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .contact-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: start;
      justify-content: space-between;
      .contact-form {
        width: 48%;
        form {
          display: flex;
          align-items: start;
          justify-content: start;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          .form-heading {
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--bg-two);
            font-family: "Jost", sans-serif;
          }
          .contact-input {
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            gap: 0.2rem;
            input {
              width: 100%;
              padding: 0.8rem 1rem;
              outline: none;
              font-size: 0.8rem;
              font-weight: 400;
              border: none;
              font-family: "Jost", sans-serif;
            }
            label {
              font-size: 1rem;
              font-weight: 400;
              color: var(--bg-two);
              font-family: "Jost", sans-serif;
            }
            textarea {
              resize: none;
              width: 100%;
              padding: 0.8rem 1rem;
              font-family: "Jost", sans-serif;
              outline: none;
              font-size: 0.8rem;
              font-weight: 400;
              border: none;
            }
          }
          button {
            padding: 0.8rem 1rem;
            border: none;
            outline: none;
            background-color: var(--primary);
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 400;
            font-family: "Jost", sans-serif;
            cursor: pointer;
            width: 100%;
          }
        }
      }
      .maps {
        width: 48%;
        height: fit-content;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        .map-heading {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          width: 100%;
          .active-h5 {
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--bg-two);
            font-family: "Jost", sans-serif;
            border-bottom: 2px solid var(--bg-two);
            cursor: pointer;
          }
          .normal-h5 {
            font-size: 1.5rem;
            font-weight: 500;
            color: lightgray;
            font-family: "Jost", sans-serif;
            cursor: pointer;
          }
        }
        .the-map {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 2rem 1rem;
    background: var(--bg-body);
    .contact-heading {
      font-size: 4rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .contact-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: start;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 2rem;
      .contact-form {
        width: 100%;
        form {
          display: flex;
          align-items: start;
          justify-content: start;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          .form-heading {
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--bg-two);
            font-family: "Jost", sans-serif;
          }
          .contact-input {
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            gap: 0.2rem;
            input {
              width: 100%;
              padding: 0.8rem 1rem;
              outline: none;
              font-size: 0.8rem;
              font-weight: 400;
              border: none;
              font-family: "Jost", sans-serif;
            }
            label {
              font-size: 1rem;
              font-weight: 400;
              color: var(--bg-two);
              font-family: "Jost", sans-serif;
            }
            textarea {
              resize: none;
              width: 100%;
              padding: 0.8rem 1rem;
              font-family: "Jost", sans-serif;
              outline: none;
              font-size: 0.8rem;
              font-weight: 400;
              border: none;
            }
          }
          button {
            padding: 0.8rem 1rem;
            border: none;
            outline: none;
            background-color: var(--primary);
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 400;
            font-family: "Jost", sans-serif;
            cursor: pointer;
            width: 100%;
          }
        }
      }
      .maps {
        width: 100%;
        height: fit-content;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        .map-heading {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          width: 100%;
          .active-h5 {
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--bg-two);
            font-family: "Jost", sans-serif;
            border-bottom: 2px solid var(--bg-two);
            cursor: pointer;
          }
          .normal-h5 {
            font-size: 1.5rem;
            font-weight: 500;
            color: lightgray;
            font-family: "Jost", sans-serif;
            cursor: pointer;
          }
        }
        .the-map {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 2rem 1rem;
    background: var(--bg-body);
    .contact-heading {
      font-size: 4rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .contact-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: start;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 2rem;
      .contact-form {
        width: 100%;
        form {
          display: flex;
          align-items: start;
          justify-content: start;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          .form-heading {
            font-size: 1rem;
            font-weight: 500;
            color: var(--bg-two);
            font-family: "Jost", sans-serif;
          }
          .contact-input {
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            gap: 0.2rem;
            input {
              width: 100%;
              padding: 0.8rem 1rem;
              outline: none;
              font-size: 0.8rem;
              font-weight: 400;
              border: none;
              font-family: "Jost", sans-serif;
            }
            label {
              font-size: 1rem;
              font-weight: 400;
              color: var(--bg-two);
              font-family: "Jost", sans-serif;
            }
            textarea {
              resize: none;
              width: 100%;
              padding: 0.8rem 1rem;
              font-family: "Jost", sans-serif;
              outline: none;
              font-size: 0.8rem;
              font-weight: 400;
              border: none;
            }
          }
          button {
            padding: 0.8rem 1rem;
            border: none;
            outline: none;
            background-color: var(--primary);
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 400;
            font-family: "Jost", sans-serif;
            cursor: pointer;
            width: 100%;
          }
        }
      }
      .maps {
        width: 100%;
        height: fit-content;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        .map-heading {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          width: 100%;
          .active-h5 {
            font-size: 1rem;
            font-weight: 500;
            color: var(--bg-two);
            font-family: "Jost", sans-serif;
            border-bottom: 2px solid var(--bg-two);
            cursor: pointer;
          }
          .normal-h5 {
            font-size: 1rem;
            font-weight: 500;
            color: lightgray;
            font-family: "Jost", sans-serif;
            cursor: pointer;
          }
        }
        .the-map {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default Contact;
