import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import { IoBedOutline } from "react-icons/io5";
import { TiGroupOutline } from "react-icons/ti";
import styled from "styled-components";
import { ourRooms } from "../../assets/projectData";
import { useNavigate } from "react-router-dom";

const RoomCard = () => {
const navigate = useNavigate();
  return (
    <RoomCardSection>
      <div className="room-cards">
        {ourRooms.map((room, index) => (
          <div className="card" key={index} >
            <div className="price-tag">
              {room.withAC === 0 ? null : (
                <p className="amount">
                  &#x20A6; {room.withAC} <span>with AC</span>
                </p>
              )}
              <p className="amount">
                &#x20A6; {room.fan} <span>without AC</span>
              </p>
            </div>
            <div className="image">
              <img src={room.image} alt="room-imgs" />
            </div>
            <div className="card-content">
              <p className="card-title">{room.name}</p>
              <div className="info">
                <p>
                  <IoBedOutline /> <span>{room.bed} King Beds</span>
                </p>
                <p>
                  <TiGroupOutline /> <span>{room.guests} Guests</span>
                </p>
              </div>
              <button onClick={() => navigate('/contact')}>
                BOOK NOW
                <span>
                  <FaChevronRight size={10} />
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </RoomCardSection>
  );
};
const RoomCardSection = styled.div`
  width: 100%;
  height: 100%;
  .room-cards {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    padding: 2rem 10rem;
    .card {
      font-family: "Jost", sans-serif;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0 0 10px #0000001a;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      border-radius:.5rem;
      .price-tag {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        background: black;
        padding: 0.8rem 1.2rem;
        color: white;
        font-family: "Jost", sans-serif;
        border-radius: 0.5rem;
        position: absolute;
        top: 40%;
        left: 0;
        font-weight: 500;
      }
      .image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        padding: 2rem 1.5rem;
        background: white;
        gap: 2rem;
        .card-title {
          font-size: 1.8rem;
          font-weight: 500;
          margin: 0;
        }
        .info {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1.5rem;
          font-size: 1rem;
          p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
          }
        }
        button {
          background: none;
          font-size: 1rem;
          font-weight: 500;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-family: "Jost", sans-serif;
          gap: 0.6rem;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
    height: 100%;
    .room-cards {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      padding: 2rem 10rem;
      .card {
        font-family: "Jost", sans-serif;
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 0 10px #0000001a;
        // border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        .price-tag {
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          background: black;
          padding: 0.8rem 1.2rem;
          color: white;
          font-family: "Jost", sans-serif;
          border-radius: 0.5rem;
          position: absolute;
          top: 40%;
          left: 0;
          font-weight: 500;
        }
        .image {
          width: 100%;
          height: 100%;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .card-content {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          width: 100%;
          padding: 2rem 1.5rem;
          background: white;
          gap: 2rem;
          .card-title {
            font-size: 1.8rem;
            font-weight: 500;
            margin: 0;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1.5rem;
            font-size: 1rem;
            p {
              margin-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
            }
          }
          button {
            background: none;
            font-size: 1rem;
            font-weight: 500;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-family: "Jost", sans-serif;
            gap: 0.6rem;
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
  width: 100%;
  height: 100%;
  .room-cards {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    padding: 2rem 1rem;
    .card {
      font-family: "Jost", sans-serif;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0 0 10px #0000001a;
      // border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .price-tag {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        background: black;
        padding: 0.8rem 1.2rem;
        color: white;
        font-family: "Jost", sans-serif;
        border-radius: 0.5rem;
        position: absolute;
        top: 40%;
        left: 0;
        font-weight: 500;
      }
      .image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        padding: 2rem 1.5rem;
        background: white;
        gap: 2rem;
        .card-title {
          font-size: 1.8rem;
          font-weight: 500;
          margin: 0;
        }
        .info {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1.5rem;
          font-size: 1rem;
          p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
          }
        }
        button {
          background: none;
          font-size: 1rem;
          font-weight: 500;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-family: "Jost", sans-serif;
          gap: 0.6rem;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  }
  @media screen and (max-width: 900px) {
  width: 100%;
  height: 100%;
  .room-cards {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    padding: 2rem 1rem;
    .card {
      font-family: "Jost", sans-serif;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0 0 10px #0000001a;
      // border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .price-tag {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        background: black;
        padding: 0.8rem 1.2rem;
        color: white;
        font-family: "Jost", sans-serif;
        border-radius: 0.5rem;
        position: absolute;
        top: 40%;
        left: 0;
        font-weight: 500;
      }
      .image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        padding: 2rem 1.5rem;
        background: white;
        gap: 2rem;
        .card-title {
          font-size: 1.8rem;
          font-weight: 500;
          margin: 0;
        }
        .info {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1.5rem;
          font-size: 1rem;
          p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
          }
        }
        button {
          background: none;
          font-size: 1rem;
          font-weight: 500;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-family: "Jost", sans-serif;
          gap: 0.6rem;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  }
  @media screen and (max-width: 600px) {
  width: 100%;
  height: 100%;
  .room-cards {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    padding: 2rem 1rem;
    .card {
      font-family: "Jost", sans-serif;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0 0 10px #0000001a;
      // border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .price-tag {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        background: black;
        padding: 0.8rem 1.2rem;
        color: white;
        font-family: "Jost", sans-serif;
        border-radius: 0.5rem;
        position: absolute;
        top: 40%;
        left: 0;
        font-weight: 500;
      }
      .image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .card-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        padding: 2rem 1.5rem;
        background: white;
        gap: 2rem;
        .card-title {
          font-size: 1.8rem;
          font-weight: 500;
          margin: 0;
        }
        .info {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1.5rem;
          font-size: 1rem;
          p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
          }
        }
        button {
          background: none;
          font-size: 1rem;
          font-weight: 500;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-family: "Jost", sans-serif;
          gap: 0.6rem;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default RoomCard;
