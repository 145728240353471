import React, { useEffect } from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageOne from "../../assets/rooms/Agbo/1.jpg";
import ImageTwo from "../../assets/rooms/Agbo/2.jpg";
import ImageThree from "../../assets/rooms/Agbo/3.jpg";
import ImageFour from "../../assets/rooms/Agbo/4.jpg";
// import ImageFive from "../../assets/rooms/Agbo/5.jpg";
import ImageSix from "../../assets/rooms/Agbo/6.jpg";
import ImageSeven from "../../assets/rooms/Agbo/7.jpg";
import ImageEight from "../../assets/rooms/Agbo/8.jpg";
// import ImageFive from "../../assets/rooms/Agbo/5.jpg";
import "react-lazy-load-image-component/src/effects/blur.css";
import AOS from "aos";
import AgborHero from "./AgborHero";

const Agbor = () => {
     useEffect(() => {
       AOS.init({
         offset: 10,
         duration: 800,
         easing: "ease-in-sine",
         delay: 100,
       });
     }, []);
  return (
    <AgborPage>
      <AgborHero />
      <div className="the-map">
        <div className="images">
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageSeven} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageEight} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageSix} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageOne} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageTwo} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageThree} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageFour} effect="blur" />
          </div>
          {/* <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageFive} effect="blur" />
          </div> */}
        </div>
        <iframe
          data-aos="zoom-in"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.0873295273122!2d6.213661985126019!3d6.252223610122183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10414e37a4f60a53%3A0x692ff7e54e27fff1!2s20%20Deeper%20Life%20Rd%2C%20Boji%20Boji%2C%20Agbor%20321103%2C%20Delta!5e0!3m2!1sen!2sng!4v1727558895577!5m2!1sen!2sng"
          width="100%"
          height="550"
          style={{
            border: 0,
            borderRadius: ".5rem",
            boxShadow: "0 0 10px #0000001a",
          }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </div>
    </AgborPage>
  );
};


const AgborPage = styled.div`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: var(--bg-body);
  .the-map {
    padding: 2rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    .images {
      display: flex;
      align-items: start;
      justify-content: start;
      flex-wrap: wrap;
      gap: 4rem;
      .image-container {
        width: 30rem;
        height: 20rem;
        overflow: hidden;
        border-radius: 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom;
          transition: all 0.5s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1300px) {
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: var(--bg-body);
    .the-map {
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      .images {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 4rem;
        .image-container {
          width: 100%;
          height: 18rem;
          overflow: hidden;
          border-radius: 1rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.5s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: var(--bg-body);
    .the-map {
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4rem;
      .images {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2rem;
        .image-container {
          width: 100%;
          height: 12rem;
          overflow: hidden;
          border-radius: 1rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.5s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
  }
`;
export default Agbor;
