import React, { useState, useEffect } from "react";
import styled from "styled-components";

const FadeInCarousel = ({ images, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const slideInterval = setInterval(nextSlide, interval);

    return () => clearInterval(slideInterval);
  }, [currentIndex, images.length, interval]);

  return (
    <CarouselContainer>
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? "active" : ""}`}
        >
          <img src={image} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      opacity: 1;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }
  }
`;

export default FadeInCarousel;
