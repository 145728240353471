import React, { useEffect } from "react";
import styled from "styled-components";
import LagosHero from "./LagosHero";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageOne from "../../assets/rooms/IMG_2977.JPG";
import ImageTwo from "../../assets/rooms/IMG_2993.JPG";
import ImageThree from "../../assets/rooms/IMG_3136.JPG";
import ImageFour from "../../assets/rooms/IMG_3124.JPG";
import ImageFive from "../../assets/rooms/IMG_3130.JPG";
import ImageSix from "../../assets/rooms/IMG_3131.JPG";
import "react-lazy-load-image-component/src/effects/blur.css";
import AOS from "aos";

const Lagos = () => {
  useEffect(() => {
    AOS.init({
      offset: 10,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <LagosPage>
      <LagosHero />
      <div className="the-map">
        <div className="images">
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageOne} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageTwo} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageThree} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageFour} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageFive} effect="blur" />
          </div>
          <div className="image-container" data-aos="zoom-in-right">
            <LazyLoadImage src={ImageSix} effect="blur" />
          </div>
        </div>
        <iframe
          data-aos="zoom-in"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d495.4583556013619!2d3.365731!3d6.563669!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d778a78bddd%3A0x9318405b55be7baf!2sCreme%20Suites%20And%20Hotel!5e0!3m2!1sen!2sng!4v1727556477314!5m2!1sen!2sng"
          width="100%"
          height="550"
          style={{
            border: 0,
            borderRadius: ".5rem",
            boxShadow: "0 0 10px #0000001a",
          }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </div>
    </LagosPage>
  );
};

const LagosPage = styled.div`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: var(--bg-body);
  .the-map {
    padding: 2rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    .images {
      display: flex;
      align-items: start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 4rem;
      .image-container {
        width: 30rem;
        height: 18rem;
        overflow: hidden;
        border-radius: 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: all 0.5s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1300px) {
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: var(--bg-body);
    .the-map {
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      .images {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 4rem;
        .image-container {
          width: 100%;
          height: 18rem;
          overflow: hidden;
          border-radius: 1rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.5s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: var(--bg-body);
    .the-map {
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4rem;
      .images {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2rem;
        .image-container {
          width: 100%;
          height: 12rem;
          overflow: hidden;
          border-radius: 1rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.5s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
  }
`;
export default Lagos;
