import React, { useEffect } from "react";
import styled from "styled-components";
import Reception from "../assets/reception.jpg";
import TheSide from "../assets/TheSide.jpg";
import TheBar from "../assets/caraousel2.png";
import  AOS  from "aos";

const About = () => {
  useEffect(() => {
    AOS.init({
      offset: 10,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <AboutSection>
      <div className="about-content">
        <div className="about-writeup">
          <h3 className="about-heading" data-aos="zoom-in-up">
            About Us
          </h3>
          <div className="about-write-up">
            <p data-aos="zoom-in-up">
              Welcome to Our Hotel, where comfort meets elegance in two prime
              locations: Maryland, Lagos State, and Agbor, Delta State. Whether
              you’re visiting for business or leisure, we offer a blend of
              modern amenities, personalized service, and serene environments
              tailored to meet your needs. Each branch provides luxurious
              accommodations, fine dining, and exceptional hospitality, ensuring
              an unforgettable stay. Experience convenience and tranquility at
              Our Hotel, your home away from home.
            </p>
          </div>
        </div>
        <div className="about-image-section">
          <div className="about-image-grid">
            <div className="big-image-container">
              <img
                className="main-image"
                src={TheSide}
                alt="about-image"
                data-aos="zoom-in"
              />
            </div>
            <div className="small-images-grid">
              <div className="small-image-container">
                <img
                  className="small-image"
                  src={Reception}
                  alt="small-image"
                  data-aos="fade-up-left"
                />
              </div>
              <div className="small-image-container small-image-container-2">
                <img
                  className="small-image"
                  src={TheBar}
                  alt="small-image"
                  data-aos="fade-down-left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutSection>
  );
};
const AboutSection = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .about-content {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    padding: 2rem 10rem;
    .about-writeup {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .about-heading {
        font-size: 4rem;
        font-weight: 500;
        color: var(--bg-two);
        font-family: "Allison", sans-serif;
      }
      .about-write-up {
        font-size: 1.2rem;
        font-weight: 400;
        color: var(--bg-two);
        text-align: center;
        font-family: "Jost", sans-serif;
        letter-spacing: 2px;
      }
    }
    .about-image-section {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .about-image-grid {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem 2rem 2rem 2rem;
        gap: 1rem;
        overflow: hidden;
        .big-image-container {
          width: 100%;
          height: 700px;
          overflow: hidden;
          .main-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
            transition: all 0.5s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: all 0.5s ease-in-out;
            }
          }
        }
        .small-images-grid {
          width: 100%;
          height: 700px;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          overflow: hidden;
          .small-image-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              transition: all 0.5s ease-in-out;
              &:hover {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .about-content {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      padding: 2rem 10rem;
      .about-writeup {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .about-heading {
          font-size: 4rem;
          font-weight: 500;
          color: var(--bg-two);
          font-family: "Allison", sans-serif;
        }
        .about-write-up {
          font-size: 1.2rem;
          font-weight: 400;
          color: var(--bg-two);
          text-align: center;
          font-family: "Jost", sans-serif;
          letter-spacing: 2px;
        }
      }
      .about-image-section {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .about-image-grid {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10rem 0 10rem 0;
          gap: 1rem;
          overflow: hidden;
          .big-image-container {
            width: 100%;
            height: 500px;
            overflow: hidden;
            .main-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              transition: all 0.5s ease-in-out;
              &:hover {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
              }
            }
          }

          .small-images-grid {
            width: 100%;
            height: 500px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            overflow: hidden;
            .small-image-container {
              width: 100%;
              height: 100%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all 0.5s ease-in-out;
                &:hover {
                  transform: scale(1.1);
                  transition: all 0.5s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      .about-content {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 3rem;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
        .about-writeup {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          .about-heading {
            font-size: 4rem;
            font-weight: 500;
            color: var(--bg-two);
            font-family: "Allison", sans-serif;
          }
          .about-write-up {
            font-size: 1.2rem;
            font-weight: 400;
            color: var(--bg-two);
            text-align: center;
            font-family: "Jost", sans-serif;
            letter-spacing: 2px;
          }
        }
        .about-image-grid {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10rem 0 10rem 0;
          gap: 1rem;
          overflow: hidden;

          .big-image-container {
            width: 100%;
            height: 400px;
            overflow: hidden;
            .main-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              transition: all 0.5s ease-in-out;
              &:hover {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
              }
            }
          }

          .small-images-grid {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .about-content {
      flex-direction: column;
      .about-writeup {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .about-heading {
          font-size: 3rem;
          font-weight: 500;
          color: var(--bg-two);
          font-family: "Allison", sans-serif;
        }
        .about-write-up {
          font-size: 0.8rem;
          font-weight: 400;
          color: var(--bg-two);
          text-align: center;
          font-family: "Jost", sans-serif;
          letter-spacing: 2px;
        }
      }
      .about-image-section {
        width: 100%;
        .about-image-grid {
          width: 100%;
          border-radius: 1rem;
          .small-images-grid {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;

export default About;
