import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../assets/Logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { RiMenu5Fill } from "react-icons/ri";

const activeLink = ({ isActive }) => (isActive ? "activeLink" : "darkLink");

const MainNav = () => {
  const [branches, setBranches] = useState(false);
  const [navReveal, setNavReveal] = useState(false);
  const navigate = useNavigate();
  if (navReveal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  const revealBranches = () => {
    setBranches(!branches);
  };

  const revealNavbar = () => {
    setNavReveal(!navReveal);
  };
  const clickLagos = () => {
    revealBranches();
    navigate("/lagos");
    revealNavbar();
  };
  const clickDelta = () => {
    revealBranches();
    navigate("/agbor");
    revealNavbar();
  };
  return (
    <NavigationProper>
      <p className="hamburger">
        <RiMenu5Fill />
      </p>
      <div className="bigScreen">
        <div className="navLinks">
          <NavLink to="/" className={activeLink}>
            HOME
          </NavLink>
          <NavLink to="/rooms" className={activeLink}>
            ROOMS
          </NavLink>
          <div className="branches" onClick={revealBranches}>
            <p to="" className={"branch"}>
              BRANCHES{" "}
              <span>{branches ? <FaChevronUp /> : <FaChevronDown />}</span>
            </p>
            <div className="openBranch">
              <div
                className={branches ? "branchList" : "branchList-close"}
                onClick={clickLagos}
              >
                <a href="/lagos">Lagos</a>
                <a href="/agbor">Delta</a>
              </div>
            </div>
          </div>
          <NavLink to="/contact" className={activeLink}>
            CONTACT
          </NavLink>
        </div>
        <Link to="/" className="logo">
          <img src={Logo} alt="" />
        </Link>
        <div className="bookNow">
          <div className="contact">
            <p>
              <FaPhone /> <a href="tel:+2348033954540">+234 912 354 8725</a>
            </p>{" "}
            <span>||</span>
            <p>
              <MdOutlineEmail />{" "}
              <a href="mailto:creamesuites@gmail.com">creamesuites@gmail.com</a>
            </p>
          </div>
          <Link to={"/contact"} className="basic-link">
            Book Now
          </Link>
        </div>
      </div>

      <div className="smallScreen">
        <Link to="/" className="logo">
          <img src={Logo} alt="" />
        </Link>
        <div className={navReveal ? "navLinks" : "navLinks-close"}>
          <NavLink to="/" className={activeLink} onClick={revealNavbar}>
            Home
          </NavLink>
          <NavLink to="/rooms" className={activeLink} onClick={revealNavbar}>
            Rooms
          </NavLink>
          <div className="branches" onClick={revealBranches}>
            <p className={"branch"}>
              Branches{" "}
              <span>{branches ? <FaChevronUp /> : <FaChevronDown />}</span>
            </p>
          </div>
          <div className={branches ? "openBranch" : "closeBranch"}>
            <ul className={branches ? "branchList" : "branchList-close"}>
              <li onClick={clickLagos}>Lagos</li>
              <li onClick={clickDelta}>Delta</li>
            </ul>
          </div>
          <NavLink to="/contact" className={activeLink} onClick={revealNavbar}>
            Contact
          </NavLink>
          <div className="bookNow">
            <div className="contact">
              <p>
                <FaPhone />{" "}
                <a href="tel:+2348033954540" onClick={revealNavbar}>
                  +2348033954540
                </a>
              </p>{" "}
              <span>||</span>
              <p>
                <MdOutlineEmail />{" "}
                <a href="mailto:creamesuites@gmail.com" onClick={revealNavbar}>
                  creamesuites@gmail.com
                </a>
              </p>
            </div>
            <Link to={"/contact"} className="basic-link" onClick={revealNavbar}>
              Book Now
            </Link>
          </div>
        </div>
        <p className="hamburger" onClick={revealNavbar}>
          <RiMenu5Fill />
        </p>
      </div>
    </NavigationProper>
  );
};

const NavigationProper = styled.div`
  width: 100%;
  position: relative;
  .bigScreen {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .smallScreen {
    display: none;
  }
  .logo {
    img {
      width: 8em;
    }
  }
  .branch {
    color: white;
  }
  .navLinks,
  .navLinks-close {
    display: flex;
    align-items: center;
    justify-content: center;
    display: relative;
    gap: 5rem;
    p {
      font-size: 1rem;
      font-family: "Jost", sans-serif;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-weight: 500;
      cursor: pointer;
      span {
        margin-top: 0.4rem;
      }
    }
    a {
      font-size: 1rem;
      font-family: "Jost", sans-serif;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-weight: 500;
      span {
        margin-top: 0.4rem;
      }
    }
    .branches {
      width: 100%;
      position: relative;
      display: block;
      .openBranch {
        position: absolute;
        top: 100%;
        height: 6rem;
        width: 6rem;
        transition: var(--transition);
        overflow: hidden;

        .branchList {
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;
          position: absolute;
          top: 0%;
          transition: var(--transition);
        }
        .branchList-close {
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;
          position: absolute;
          top: -100%;
          transition: var(--transition);
        }
        a {
          list-style: none;
          font-family: "Jost", sans-serif;
          cursor: pointer;
          transition: var(--transition);
          width: 100%;
          text-align: center;
          padding: 0.5rem 0;
          text-decoration: none;
          color: black;
          &:hover {
            background: var(--primary);
            color: white;
            transition: var(--transition);
          }
        }
      }
    }
  }
  .bookNow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    .contact {
      font-size: 1rem;
      font-family: "Jost", sans-serif;
      display: flex;
      align-items: start;
      justify-content: center;
      gap: 1rem;
      span {
        color: white;
      }
      p {
        font-family: "Jost", sans-serif;
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        margin-bottom: 0;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  .darkLink {
    color: white;
    font-weight: 500;
  }
  .activeLink {
    color: var(--primary);
    font-weight: 500;
  }
  .hamburger {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    position: relative;
    .bigScreen {
      display: none;
    }
    .smallScreen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .logo {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 10;
        img {
          width: 8rem;
        }
      }
      .hamburger {
        position: absolute;
        top: 2.8rem;
        right: 1rem;
        z-index: 10;
        display: flex;
        font-size: 2rem;
        color: white;
        cursor: pointer;
      }
      .navLinks-close {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;
            span {
              display: none;
            }
          }
        }
      }
      .navLinks {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;

            span {
              display: none;
            }
          }
        }
      }
      .openBranch {
        width: 100%;
        transition: var(--transition);
        .branchList {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: fit-content;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 1rem 0;
            cursor: pointer;
          }
        }
      }
      .closeBranch {
        display: none;
        transition: var(--transition);
        .branchList-close {
          display: none;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: 0rem;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 0.5rem 0;
          }
        }
      }
    }
    .darkLink {
      color: white;
      font-weight: 500;
    }
    .activeLink {
      color: black;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    position: relative;
    .bigScreen {
      display: none;
    }
    .smallScreen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .logo {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 10;
        img {
          width: 8rem;
        }
      }
      .hamburger {
        position: absolute;
        top: 2.8rem;
        right: 1rem;
        z-index: 10;
        display: flex;
        font-size: 2rem;
        color: white;
        cursor: pointer;
      }
      .navLinks-close {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;
            span {
              display: none;
            }
          }
        }
      }
      .navLinks {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;

            span {
              display: none;
            }
          }
        }
      }
      .openBranch {
        width: 100%;
        transition: var(--transition);
        .branchList {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: fit-content;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 1rem 0;
            cursor: pointer;
          }
        }
      }
      .closeBranch {
        display: none;
        transition: var(--transition);
        .branchList-close {
          display: none;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: 0rem;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    .bigScreen {
      display: none;
    }
    .smallScreen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .logo {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 10;
        img {
          width: 8rem;
        }
      }
      .hamburger {
        position: absolute;
        top: 2.8rem;
        right: 1rem;
        z-index: 10;
        display: flex;
        font-size: 2rem;
        color: white;
        cursor: pointer;
      }
      .navLinks-close {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;
            span {
              display: none;
            }
          }
        }
      }
      .navLinks {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;

            span {
              display: none;
            }
          }
        }
      }
      .openBranch {
        width: 100%;
        transition: var(--transition);
        .branchList {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: fit-content;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 1rem 0;
            cursor: pointer;
          }
        }
      }
      .closeBranch {
        display: none;
        transition: var(--transition);
        .branchList-close {
          display: none;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: 0rem;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    position: relative;
    .bigScreen {
      display: none;
    }
    .smallScreen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .logo {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 10;
        img {
          width: 8rem;
        }
      }
      .hamburger {
        position: absolute;
        top: 2.8rem;
        right: 1rem;
        z-index: 10;
        display: flex;
        font-size: 2rem;
        color: white;
        cursor: pointer;
      }
      .navLinks-close {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;
            span {
              display: none;
            }
          }
        }
      }
      .navLinks {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;

            span {
              display: none;
            }
          }
        }
      }
      .openBranch {
        width: 100%;
        transition: var(--transition);
        .branchList {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: fit-content;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 1rem 0;
            cursor: pointer;
          }
        }
      }
      .closeBranch {
        display: none;
        transition: var(--transition);
        .branchList-close {
          display: none;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: 0rem;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    position: relative;
    .bigScreen {
      display: none;
    }
    .smallScreen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .logo {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 10;
        img {
          width: 8rem;
        }
      }
      .hamburger {
        position: absolute;
        top: 2.8rem;
        right: 1rem;
        z-index: 10;
        display: flex;
        font-size: 2rem;
        color: white;
        cursor: pointer;
      }
      .navLinks-close {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;
            span {
              display: none;
            }
          }
        }
      }
      .navLinks {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: var(--primary);
        gap: 3rem;
        padding-top: 8rem;
        transition: var(--transition);
        .bookNow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3rem;
          flex-direction: column;
          .contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;

            span {
              display: none;
            }
          }
        }
      }
      .openBranch {
        width: 100%;
        transition: var(--transition);
        .branchList {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: fit-content;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 1rem 0;
            cursor: pointer;
          }
        }
      }
      .closeBranch {
        display: none;
        transition: var(--transition);
        .branchList-close {
          display: none;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          background: white;
          margin-top: -2rem;
          height: 0rem;
          transition: var(--transition);
          li {
            list-style: none;
            font-size: 1rem;
            font-family: "Jost", sans-serif;
            color: var(--primary);
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
`;
export default MainNav;
