import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { reviews } from "../../assets/projectData";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <TestimonialPage>
      <div className="testimonial-container">
        <h1 className="testimonial-heading">Testimonials</h1>
        <div className="sliderContent">
          <Slider {...settings}>
            {reviews.map((item, index) => (
              <div key={index}>
                <div className="Cleancard">
                  <div className="namePics">
                    <p className="card-bottom">{item.description}</p>
                    <br />
                    <p className="name">{item.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </TestimonialPage>
  );
};

const TestimonialPage = styled.div`
  width: 100%;
  min-height: 50vh;
  position: relative;
  height: fit-content;
  padding: 2rem 10rem;
  .testimonial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    .testimonial-heading {
      font-size: 4rem;
      font-family: "Allison", system-ui;
      color: var(--bg-two);
      font-weight: 500;
    }
    .sliderContent {
      width: 40rem;
      margin: auto;
    }

    .Cleancard {
      width: 35rem;
      height: 12rem;
      display: flex;
      align-items: center;
      gap: 1.3rem;
      justify-content: center;
      /* border: 1px solid var(--primary); */
      box-shadow: 0 0 10px #0000001a;

      border-radius: 5%;
      margin: 2rem auto;
      padding: 2rem;
      background: white;
    }
    .Cleancard .name {
      font-weight: 600;
      text-align: center;
      font-family: "Jost", sans-serif;
      font-size: 1rem;
    }
    .Cleancard .card-bottom {
      width: 100%;
      text-align: center;
      line-height: 1.5rem;
      color: var(--text-primary);
      font-family: "Jost", sans-serif;
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 1200px) {
    /* background: var(--bg-body); */
    width: 100%;
    min-height: 50vh;
    position: relative;
    height: fit-content;
    padding: 2rem 1rem;
    .testimonial-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      .testimonial-heading {
        font-size: 4rem;
        font-family: "Allison", system-ui;
        color: var(--bg-two);
        font-weight: 500;
      }
      .sliderContent {
        width: 85%;
        margin: auto;
      }

      .Cleancard {
        width: 100%;
        height: 12rem;
        display: flex;
        align-items: center;
        gap: 1.3rem;
        justify-content: center;
        /* border: 1px solid var(--primary); */
        box-shadow: 0 0 10px #0000001a;

        border-radius: 5%;
        margin: 2rem auto;
        padding: 2rem;
        background: white;
      }
      .Cleancard .name {
        font-weight: 600;
        text-align: center;
        font-family: "Jost", sans-serif;
        font-size: 1rem;
      }
      .Cleancard .card-bottom {
        width: 100%;
        text-align: center;
        line-height: 1.5rem;
        color: var(--text-primary);
        font-family: "Jost", sans-serif;
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-width: 900px) {
    /* background: var(--bg-body); */
    width: 100%;
    min-height: 50vh;
    position: relative;
    height: fit-content;
    padding: 2rem 1rem;
    .testimonial-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      .testimonial-heading {
        font-size: 4rem;
        font-family: "Allison", system-ui;
        color: var(--bg-two);
        font-weight: 500;
      }
      .sliderContent {
        width: 85%;
        margin: auto;
      }

      .Cleancard {
        width: 100%;
        height: 12rem;
        display: flex;
        align-items: center;
        gap: 1.3rem;
        justify-content: center;
        /* border: 1px solid var(--primary); */
        box-shadow: 0 0 10px #0000001a;

        border-radius: 5%;
        margin: 2rem auto;
        padding: 2rem;
        background: white;
      }
      .Cleancard .name {
        font-weight: 600;
        text-align: center;
        font-family: "Jost", sans-serif;
        font-size: 1rem;
      }
      .Cleancard .card-bottom {
        width: 100%;
        text-align: center;
        line-height: 1.5rem;
        color: var(--text-primary);
        font-family: "Jost", sans-serif;
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    min-height: 50vh;
    position: relative;
    height: fit-content;
    padding: 2rem 1rem;
    .testimonial-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      .testimonial-heading {
        font-size: 4rem;
        font-family: "Allison", system-ui;
        color: var(--bg-two);
        font-weight: 500;
      }
      .sliderContent {
        width: 85%;
        margin: auto;
      }

      .Cleancard {
        width: 100%;
        height: 12rem;
        display: flex;
        align-items: center;
        gap: 1.3rem;
        justify-content: center;
        /* border: 1px solid var(--primary); */
        box-shadow: 0 0 10px #0000001a;

        border-radius: 5%;
        margin: 2rem auto;
        padding: 2rem;
        background: white;
      }
      .Cleancard .name {
        font-weight: 600;
        text-align: center;
        font-family: "Jost", sans-serif;
        font-size: 1rem;
      }
      .Cleancard .card-bottom {
        width: 100%;
        text-align: center;
        line-height: 1.5rem;
        color: var(--text-primary);
        font-family: "Jost", sans-serif;
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default Testimonials;
