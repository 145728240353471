import React from "react";
import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import styled from "styled-components";
import Logo from "../../assets/Logo.png";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <FooterSect>
      <div className="footer">
        <div className="top">
          <div className="one">
            <img src={Logo} alt="" />
            <div className="social-icons">
              <FaFacebookF />
              <IoLogoInstagram />
              <FaTwitter />
              <a
                href="https://wa.me/message/4K7Q26IBVP36P1"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp />
              </a>
            </div>
          </div>
          <div className="two">
            <p>Hotel Addresses</p>
            <div className="address">
              <span>
                <b>Lagos :</b> 2B, Iya Oloye Crescent, idiroke Estate, Maryland
                Lagos State
              </span>
              <span>
                <b>Delta (Agor) : </b> House 20, Deeper life road, Boji Boji,
                Owa, Off Old Lagos-Asaba Road, Agbor, Delta State.
              </span>
            </div>
          </div>
          <div className="three">
            <p>Contact</p>
            <div className="contacts">
              <p>
                Lagos: <span>+234 9123548725</span>{" "}
              </p>
              <p>
                Delta (Agbor): <span>+2347063985240</span>{" "}
              </p>
              <p>
                Email: <span>cremesuites@gmail.com</span>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="footer-links">
            <Link to="/">HOME</Link>
            <Link to="/rooms">ROOMS</Link>
            {/* <Link to="/">BRANCHES</Link> */}
            <Link to="/contact">CONTACT</Link>
          </div>
          <div className="terms">
            <p>
              Copyright &copy; 2024. Creme Suites Hotel. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </FooterSect>
  );
};
const FooterSect = styled.div`
  width: 100%;
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-body);
    padding: 1rem 1rem;
    gap: 1rem;
    span {
      font-weight: 400;
      color: white;
      font-family: "Jost", sans-serif;
      font-size: 1rem;
    }
    .top {
      width: 100%;
      background-color: var(--primary);
      display: flex;
      justify-content: space-between;
      padding: 2rem 9rem;
      border-radius: 1rem;
      gap: 2rem;

      .one,
      .two,
      .three {
        height: fit-content;
        padding: 2rem 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        img {
          width: 8rem;
        }
        .social-icons {
          color: var(--bg-one);
          display: flex;
          gap: 1rem;
          align-items: center;
          font-size: 1rem;
          cursor: pointer;
        }
        a {
          color: var(--bg-one);
          display: flex;
          gap: 1rem;
          align-items: center;
          font-size: 1rem;
          cursor: pointer;
        }
        p {
          color: var(--bg-one);
          font-size: 1rem;
          letter-spacing: 1px;
          font-weight: 600;
          font-family: "Jost", sans-serif;
        }
        h4 {
          color: var(--bg-one);
          font-size: 1.2rem;
          font-weight: 400;
          letter-spacing: 1px;
          font-family: "Jost", sans-serif;
        }

        .address {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
        }

        .contacts {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
        }
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 2rem 10rem;
      background-color: var(--primary);
      border-radius: 1rem;
      .footer-links {
        display: flex;
        gap: 1rem;
        a {
          text-decoration: none;
          color: var(--bg-one);
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 1px;
          font-family: "Jost", sans-serif;
        }
      }
      .terms {
        p {
          color: var(--bg-one);
          font-size: 0.8rem;
          font-weight: 400;
          letter-spacing: 1px;
          font-family: "Jost", sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .footer {
      padding: 1rem 1rem;
      .top {
        padding: 2rem 1rem;
      }
      .bottom {
        padding: 2rem 1rem;
        .footer-links {
          gap: 1rem;
          align-items: center;
          justify-content: center;
          a {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .bottom {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    .footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: var(--bg-body);
      padding: 0rem;
      gap: 1rem;
      .top {
        width: 100%;
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0rem 0;
        border-radius: 0rem;
        gap: 0.5rem;

        .one,
        .two,
        .three {
          width: 100%;
          height: fit-content;
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          border-top: 1px solid var(--bg-body);
          border-bottom: 1px solid var(--bg-body);
          img {
            width: 6rem;
          }
          .social-icons {
            color: var(--bg-one);
            display: flex;
            gap: 1rem;
            align-items: center;
            font-size: 0.8rem;
            cursor: pointer;
          }
          p {
            color: var(--bg-one);
            font-size: 0.8rem;
            letter-spacing: 1px;
            font-variant: small-caps;
            font-weight: 700;
            font-family: "Jost", sans-serif;
          }
          h4 {
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 400;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }

          .address {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            gap: 0.5rem;
            width: 100%;
            font-size: 0.8rem;
            padding: 0 1rem;
            span {
              font-size: 0.8rem;
            }
          }

          .contacts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            width: 100%;
            font-size: 0.8rem;
          }
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        background-color: var(--primary);
        border-radius: 0rem;
        .footer-links {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          a {
            text-decoration: none;
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 500;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }
        }
        .terms {
          p {
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 400;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    .footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: var(--bg-body);
      padding: 0rem;
      gap: 1rem;
      .top {
        width: 100%;
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0rem 0;
        border-radius: 0rem;
        gap: 0.5rem;

        .one,
        .two,
        .three {
          width: 100%;
          height: fit-content;
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          border-top: 1px solid var(--bg-body);
          border-bottom: 1px solid var(--bg-body);
          img {
            width: 6rem;
          }
          .social-icons {
            color: var(--bg-one);
            display: flex;
            gap: 1rem;
            align-items: center;
            font-size: 1rem;
            cursor: pointer;
          }
          p {
            color: var(--bg-one);
            font-size: 0.8rem;
            letter-spacing: 1px;
            font-variant: small-caps;
            font-weight: 700;
            font-family: "Jost", sans-serif;
          }
          h4 {
            color: var(--bg-one);
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }

          .address {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            width: 100%;
            font-size: 0.8rem;
          }

          .contacts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            width: 100%;
            font-size: 0.8rem;
          }
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        background-color: var(--primary);
        border-radius: 0rem;
        .footer-links {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          a {
            text-decoration: none;
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 500;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }
        }
        .terms {
          p {
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 400;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    .footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: var(--bg-body);
      padding: 0rem;
      gap: 1rem;
      .top {
        width: 100%;
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0rem 0;
        border-radius: 0rem;
        gap: 0.5rem;

        .one,
        .two,
        .three {
          width: 100%;
          height: fit-content;
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          border-top: 1px solid var(--bg-body);
          border-bottom: 1px solid var(--bg-body);
          img {
            width: 6rem;
          }
          .social-icons {
            color: var(--bg-one);
            display: flex;
            gap: 1rem;
            align-items: center;
            font-size: 1rem;
            cursor: pointer;
          }
          p {
            color: var(--bg-one);
            font-size: 0.8rem;
            letter-spacing: 1px;
            font-variant: small-caps;
            font-weight: 700;
            font-family: "Jost", sans-serif;
          }
          h4 {
            color: var(--bg-one);
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }

          .address {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            width: 100%;
            font-size: 0.8rem;
          }

          .contacts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            width: 100%;
            font-size: 0.8rem;
          }
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        background-color: var(--primary);
        border-radius: 0rem;
        .footer-links {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          a {
            text-decoration: none;
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 500;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }
        }
        .terms {
          p {
            color: var(--bg-one);
            font-size: 0.8rem;
            font-weight: 400;
            letter-spacing: 1px;
            font-family: "Jost", sans-serif;
          }
        }
      }
    }
  }
`;
export default Footer;
