export const ourRooms = [
  {
    id: 1,
    name: "Deluxe",
    withAC: 22000,
    fan: 17000,
    bed: 1,
    guests: 4,
    location: "Lagos",
    image:
      "https://res.cloudinary.com/obilomania/image/upload/v1730416546/Creme%20Hotel/Deluxe.jpg",
  },
  {
    id: 2,
    name: "Executive",
    withAC: 19000,
    fan: 14000,
    bed: 1,
    guests: 4,
    location: "Lagos",
    image:
      "https://res.cloudinary.com/obilomania/image/upload/v1730806063/Creme%20Hotel/executive_sagag2.jpg",
  },
  {
    id: 4,
    name: "Twin",
    withAC: 15000,
    fan: 10000,
    bed: 1,
    guests: 4,
    location: "Lagos",
    image:
      "https://res.cloudinary.com/obilomania/image/upload/v1730416856/Creme%20Hotel/TwinRoom_ik4e8s.png",
  },
  {
    id: 3,
    name: "Standard",
    withAC: 17000,
    fan: 12000,
    bed: 1,
    guests: 4,
    location: "Lagos",
    image:
      "https://res.cloudinary.com/obilomania/image/upload/v1730805263/Creme%20Hotel/Standard.jpg",
  },
  // {
  //   id: 5,
  //   name: "Charlet",
  //   withAC: 0,
  //   fan: 6000,
  //   bed: 1,
  //   guests: 4,
  //   location: "Lagos",
  //   image:
  //     "https://res.cloudinary.com/obilomania/image/upload/v1728471886/IMG_2984_cnxsrw.jpg",
  // },
  // {
  //   id: 6,
  //   name: "Twin",
  //   withAC: 35000,
  //   fan: 25000,
  //   bed: 1,
  //   guests: 4,
  //   location: "Lagos",
  //   image:
  //     "https://res.cloudinary.com/obilomania/image/upload/v1730416856/Creme%20Hotel/TwinRoom_ik4e8s.png",
  // },
];

export const reviews = [
  {
    name: "Lucas",
    description:
      "The staffs go up and beyond to mak your stay convenient, i really enjoyed my stay and would recommend Creme Suites to anyone.",
  },
  {
    name: "Saidu Abubakar",
    description:
      "They place is very calm and reserved. my stay was comfortable and fun.",
  },
  {
    name: "Stehanie Okoye",
    description:
      "I am not the type to post a review, but they were very friendly and they went beyond my expectations.",
  },
  {
    name: "Anonymous",
    description: "A very coded, cool and reserved place.",
  },
];
